import React, {lazy} from 'react'
import { WechatOutlined, UserOutlined, ShopOutlined, LineChartOutlined, MonitorOutlined } from '@ant-design/icons';
import BlankLayout from '../layouts/blankLayout'
import BasicLayout from '../layouts/basicLayout'
import UserLayout from '../layouts/userLayout'

const router = [
    {
        path: '/',
        component: BlankLayout,
        children: [
            {
                path: '/user',
                component: UserLayout,
                children: [
                    {
                        path: '/user/login',
                        name: '登陆',
                        component: lazy(() => import('../pages/user/login'))
                    },
                    {
                        path: '/user',
                        redirect: '/user/login',
                        hidden: true
                    },
                ]
            },
            {
                path: '/',
                component: BasicLayout,
                children: [
                    {
                        path: '/pool/seasPool',
                        name: '公海池',
                        component: lazy(() => import('../pages/pool/seasPool'))
                    },
                    {
                        path: '/myInfo/setting',
                        name: '我的信息',
                        component: lazy(() => import('../pages/myInfo/setting'))

                    },
                    {
                        path: '/pool',
                        name: '客户管理',
                        children: [
                            // {
                            //     path: '/pool/seasPool',
                            //     name: '公海池',
                            //     component: lazy(() => import('../pages/pool/seasPool'))
                            // },
                            {
                                path: '/pool/myPool',
                                name: '我的客户',
                                component: lazy(() => import('../pages/pool/myPool'))
                            },
                            {
                                path: '/pool/toBeConfirmed',
                                name: '待确认客户',
                                component: lazy(() => import('../pages/pool/toBeConfirmed'))
                            },
                            {
                                path: '/pool/returnVisit',
                                name: '回访列表',
                                component: lazy(() => import('../pages/pool/returnVisit'))
                            },
                        ]
                    },

                    {
                        path: '/employee',
                        name: '员工管理',
                        children: [
                            {
                                path: '/employee/groupManage',
                                name: '分组管理',
                                component: lazy(() => import('../pages/employee/groupManage'))
                            }
                            ,
                            {
                                path: '/employee/member',
                                name: '人员管理',
                                component: lazy(() => import('../pages/employee/member'))
                            }
                        ]
                    },
                    {
                        path: '/404',
                        name: '404',
                        hidden: true,
                        component: lazy(() => import('../pages/exception/pageNotFound'))
                    },
                    { path: '/', exact: true, redirect: '/pool/seasPool', hidden: true},
                    { path: '*', exact: true, redirect: '/404', hidden: true },
                ]
            }
        ]
    }
]

export default router
